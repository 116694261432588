/* eslint-disable prettier/prettier */
export const getPhoneScreensByOrg = /* GraphQL */ `
  query GetPhoneScreensbyOrg(
    $orgID: ID!
    $statusDate: ModelPhoneScreenByOrgStatusDateCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $phoneScreensFilter: ModelPhoneScreenFilterInput
    $interviewersFilter: ModelInterviewerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrganization(id: $orgID) {
      id
      name
      phoneScreens(
        statusDate: $statusDate,
        filter: $phoneScreensFilter,
        limit: $limit,
        nextToken: $nextToken,
        sortDirection: $sortDirection
      ) {
        items {
          id
          date
          createdAt
          updatedAt
          eventendtime
          eventstarttime
          designatedRCID
          designatedRC {
            id
            alias
            name
          }
          orgID
          status
          locked
          availabilityID
          restrictedevent
          eventConfig {
            id
            candidateLevels
            name
            description
            duration
            createdAt
            updatedAt
            isActive
            orgID
            participantConfigs {
              items {
                id
                orgID
                isActive
                name
                description
                calibrationConfigID
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          interviewers(filter: $interviewersFilter) {
            items {
              id
              startDateTime
              endDateTime
              interviewerRole
              participantConfigID
              isBarRaiserInterviewer
              sourceOrgID
              profile {
                id
                alias
                name
                preferredTimezone
                title
              }
            }
          }
          candidates {
            items {
              name
              level
              id
              hireurl
              city
            }
          }
          comments {
            items {
              id
            }
          }
          taglist
        }
        nextToken
      }
    }
  }
`;

export const getPhoneScreensByInterviewerProfile = /* GraphQL */ `
  query getPhoneScreensByInterviewerProfile(
    $profileID: ID!
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    listInterviewersByProfile(
      profileID: $profileID
      sortDirection: $sortDirection
      filter: { phoneScreenID: { attributeExists: true } }
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        phoneScreen {
          id
          date
          createdAt
          updatedAt
          eventendtime
          eventstarttime
          designatedRCID
          restrictedevent
          designatedRC {
            id
            alias
            name
          }
          orgID
          status
          locked
          availabilityID
          eventConfig {
            id
            candidateLevels
            name
            description
            duration
            createdAt
            updatedAt
            isActive
            orgID
            participantConfigs {
              items {
                id
                orgID
                isActive
                name
                description
                calibrationConfigID
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          interviewers {
            items {
              id
              interviewerRole
              participantConfigID
              isBarRaiserInterviewer
              startDateTime
              endDateTime
              sourceOrgID
              profile {
                id
                alias
                name
                preferredTimezone
                title
              }
            }
          }
          candidates {
            items {
              name
              level
              id
              hireurl
              city
            }
          }
          comments(filter: { commentType: { eq: EVENT } }) {
            items {
              id
            }
          }
          taglist
        }
      }
      nextToken
    }
  }
`;

export const getOrgPhoneScreenDurationConfigs = /* GraphQL */ `
  query GetOrgPhoneScreenDurationConfigs(
    $orgID: ID!
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    listConfigsByOrgType(
      orgID: $orgID
      configType: { eq: "PHONESCREEN_CONFIG" }
      sortDirection: $sortDirection
      filter: {
        and: [
          { isActive: { eq: true } },
          { duration: { gt: 60 } },
        ]
      }
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        duration
      }
      nextToken
    }
  }
`;

export const getPhoneScreenConfigsByOrg = /* GraphQL */ `
  query GetPhoneScreenConfigsByOrg(
    $orgID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrgLoopPSConfigurationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConfigsByOrgType(
      orgID: $orgID
      configType: { eq: "PHONESCREEN_CONFIG" }
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgID
        configType
        group
        isActive
        brMode
        candidateLevels
        name
        description
        duration
        participantConfigs {
          items {
            id
            orgID
            configType
            group
            isActive
            name
            description
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getPhoneScreenConfigDefinition = /* GraphQL */ `
  query GetOrgLoopPSConfigurations($id: ID!) {
    getOrgLoopPSConfigurations(id: $id) {
      id
      orgID
      group
      isActive
      brMode
      candidateLevels
      name
      description
      duration
      participantConfigIDs
      participantConfigs {
        items {
          id
          orgID
          configType
          group
          isActive
          name
          description
          calibrationConfigID
          calibrationConfig {
            id
            orgID
            configType
            group
            isActive
            name
            description
            maxShadowPoolSize
            minShadows
            minReverseShadows
            minShepherdReviews
            minIndependent
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const getActiveProfileAvailabilitiesByOrg = /* GraphQL */ `
  query GetProfileAvailabilityByOrg(
    $orgID: ID!
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    listProfileAvailabilityByOrg(
      orgID: $orgID
      sortDirection: $sortDirection
      filter: { expired: { eq: false } }
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        profile {
          id
          alias
          name
          email
          level
          br
          preferredTimezone
          calibrations {
            items {
              id
              orgID
              shadowCount
              reverseShadowCount
              independentCount
              reviewCount
              calibrationstatus
              calibrationConfigID
            }
            nextToken
          }
          status
          title
          createdAt
          updatedAt
        }
        orgID
        requester
        hireurl
        state
        sendCalendarInvite
        expired
        eventType
        availabilityDate
        recurringEndDate
        availabilityOpen
        availabilityClose
        availabilitySeriesID
        daysOfWeek
        recursion
        rrule
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getProfileAvailability = /* GraphQL */ `
  query GetProfileAvailability($id: ID!) {
    getProfileAvailability(id: $id) {
      id
      profile {
        id
        alias
        name
        email
        level
        br
        preferredTimezone
        calibrations {
          items {
            id
            orgID
            shadowCount
            reverseShadowCount
            independentCount
            reviewCount
            calibrationstatus
            calibrationConfigID
          }
          nextToken
        }
        title
        createdAt
        updatedAt
      }
      orgID
      requester
      hireurl
      state
      sendCalendarInvite
      expired
      eventType
      availabilitySeriesID
      availabilityDate
      recurringEndDate
      availabilityOpen
      availabilityClose
      daysOfWeek
      recursion
      rrule
      createdAt
      updatedAt
    }
  }
`;

export const getPhoneScreenInterviewers = /* GraphQL */ `
  query GetInterviewersByPhoneScreen(
    $eventID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInterviewerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPhoneScreen(id: $eventID) {
      interviewers(
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          id
          profileID
          participantConfigID
          interviewerRole
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const getPhoneScreen = /* GraphQL */ `
  query GetPhoneScreen($id: ID!) {
    getPhoneScreen(id: $id) {
      availabilityID
      id
      orgID
      date
      eventstarttime
      eventendtime
      status
      locked
      designatedRCID
      designatedRC {
        id
        alias
        name
        email
      }
      candidates {
        items {
          id
          name
          city
          hireurl
          decision
          country
          level
          createdAt
          updatedAt
        }
        nextToken
      }
      interviewers {
        items {
          id
          profile {
            id
            alias
            firstName
            lastName
            name
            email
            level
            br
            status
            preferredTimezone
            title
            createdAt
            updatedAt
          }
          startDateTime
          endDateTime
          interviewerRole
          isBarRaiserInterviewer
          sendCalendarInvite
          participantConfigID
          sourceOrgID
          createdAt
          updatedAt
        }
        nextToken
      }
      eventConfig {
        id
        orgID
        configType
        group
        isActive
        brMode
        candidateLevels
        name
        description
        duration
        participantConfigIDs
        participantConfigs {
          items {
            id
            orgID
            configType
            group
            isActive
            name
            description
            calibrationConfigID
            calibrationConfig {
              id
              orgID
              configType
              group
              isActive
              candidateLevels
              name
              description
              maxShadowPoolSize
              minIndependent
              minShadows
              minReverseShadows
              minShepherdReviews
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      comments(filter: { commentType: { eq: EVENT } }) {
        items {
          id
          createdAt
          content
          restricted
          profile {
            id
            alias
            name
            email
          }
          updatedAt
        }
        nextToken
      }
      restrictedevent
      taglist
      createdAt
      updatedAt
    }
  }
`;

export const getCommentsByPhoneScreen = /* GraphQL */ `
  query ListCommentsByPhoneScreen(
    $phoneScreenID: ID!
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    listCommentsByPhoneScreen(
      phoneScreenID: $phoneScreenID
      commentTypeCreatedAt: { beginsWith: { commentType: EVENT createdAt: "" } }
      sortDirection: $sortDirection
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      items {
        id
        content
        profile {
          id
          alias
          email
          name
          title
        }
        restricted
        phoneScreenID
        createdAt
        updatedAt
      }
    }
  }
`;

