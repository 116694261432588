/* eslint-disable prettier/prettier */

export const getInterviewerProfile = /* GraphQL */ `
  query GetInterviewerProfile($id: ID!) {
    getInterviewer(id: $id) {
      id
      loopEventID
      phoneScreenID
      isBarRaiserInterviewer
      participantConfigID
      sourceOrgID
      profile {
        id
        alias
        name
      }
      interviewerRole
    }
  }
`;

export const listBarRaiserInterviewers = /* GraphQL */ `
  query ListBarRaiserInterviewers(
    $brStatus: BarRaiserStatus!
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfilesByBrStatus(
      brStatus: $brStatus,
      status: { eq: "ACTIVE" },
      filter: $filter,
      limit: $limit,
      nextToken: $nextToken
    ) {
      items {
        alias
        brStatus
        createdAt
        email
        id
        l2manager
        level
        manager
        name
        preferredTimezone
        status
        title
        updatedAt
      }
      nextToken
    }
  }
`;
