/* eslint-disable prettier/prettier */
export const getOrgProfilesWithShepherds = /* GraphQL */ `
  query GetOrgProfilesWithShepherds(
    $id: ID!
    $limit: Int
    $filter: ModelProfileOrganizationV2FilterInput
    $nextToken: String
  ) {
    getOrganization(id: $id) {
      members(
        limit: $limit,
        filter: $filter,
        nextToken: $nextToken
      ) {
        nextToken
        items {
          profile {
            id
            alias
            name
            level
            manager
            l2manager
            title
            calibrationShepherds(limit: 5000) {
              items {
                id
                calibrationShepherdProfileID
                calibrationShepherdProfile {
                  id
                  alias
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const getProfileUsersUnderShepherd = /* GraphQL */ `
  query GetProfileUsersUnderShepherd(
    $id: ID!
    $orgID: ID!
    $limit: Int
    $nextToken: String
  ) {
    getProfile(id: $id) {
      usersUnderShepherd(nextToken: $nextToken, limit: $limit) {
        nextToken
        items {
          userProfile {
            id
            name
            alias
            brStatus
            title
            level
            manager
            l2manager
            email
            status
            preferredTimezone
            calibrations(
              orgID: { eq: $orgID }
              filter: { calibrationConfigID: { attributeExists: true } },
              limit: $limit
            ) {
              items {
                calibrationstatus
                calibrationConfigID
                shadowCount
                reverseShadowCount
                independentCount
                reviewCount
              }
            }
            updatedAt
          }
        }
      }
    }
  }
`;
