export const getQuestionBankTeam = /* GraphQL */ `
  query GetQuestionBankTeam($id: ID!) {
    getQuestionBankTeam(id: $id) {
      id
      name
      group
      createdAt
      updatedAt
    }
  }
`;

export const listQuestionBankTeams = /* GraphQL */ `
  query ListQuestionBankTeams($limit: Int, $nextToken: String) {
    listQuestionBankTeams(limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        name
        group
        createdAt
        updatedAt
      }
    }
  }
`;

export const listQuestionBankTeamCategories = /* GraphQL */ `
  query ListQuestionBankTeamCategories($teamID: ID!) {
    listQuestionBankCategoriesByTeam(teamID: $teamID) {
      items {
        id
        group
        name
        description
        teamID
        createdAt
        updatedAt
        team {
          id
          name
          group
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const listQuestionBankTeamJobRoles = /* GraphQL */ `
  query ListQuestionBankTeamJobRoles($teamID: ID!) {
    listQuestionBankJobRolesByTeam(teamID: $teamID) {
      items {
        id
        group
        name
        description
        teamID
        createdAt
        updatedAt
        team {
          id
          name
          group
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const listQuestionBankTeamInterviewerRoles = /* GraphQL */ `
  query ListQuestionBankTeamInterviewerRoles($teamID: ID!) {
    listQuestionBankInterviewerRolesByTeam(teamID: $teamID) {
      items {
        id
        group
        name
        description
        teamID
        createdAt
        updatedAt
        team {
          id
          name
          group
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const listQuestionBankTeamQuestions = /* GraphQL */ `
  query ListQuestionBankTeamQuestions($limit: Int, $nextToken: String, $teamID: ID!) {
    listQuestionBankQuestionsByTeam(limit: $limit, nextToken: $nextToken, teamID: $teamID) {
      nextToken
      items {
        id
        category {
          id
          group
          name
          description
          teamID
          createdAt
          updatedAt
          team {
            id
            name
            group
            createdAt
            updatedAt
          }
        }
        categoryID
        status
        group
        name
        description
        teamID
        createdAt
        updatedAt
        shareability
        team {
          id
          name
          group
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const listQuestionBankTeamInterviewTemplates = /* GraphQL */ `
  query ListQuestionBankTeamInterviewTemplates($teamID: ID!) {
    listQuestionBankInterviewTemplatesByTeam(teamID: $teamID) {
      items {
        id
        group
        name
        description
        jobLevel
        createdAt
        updatedAt
        interviewerRole {
          id
          group
          name
          description
          teamID
          createdAt
          updatedAt
          team {
            id
            name
            group
            createdAt
            updatedAt
          }
        }
        jobRole {
          id
          group
          name
          description
          teamID
          createdAt
          updatedAt
          team {
            id
            name
            group
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;

export const listQuestionBankInterviewTemplates = /* GraphQL */ `
  query ListQuestionBankInterviewTemplates($limit: Int, $nextToken: String) {
    listQuestionBankInterviewTemplates(limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        name
        description
        jobLevel
        interviewerRole {
          id
          group
          name
          description
          teamID
          createdAt
          updatedAt
          team {
            id
            name
            group
            createdAt
            updatedAt
          }
        }
        jobRole {
          id
          group
          name
          description
          teamID
          createdAt
          updatedAt
          team {
            id
            name
            group
            createdAt
            updatedAt
          }
        }
        team {
          id
          name
          group
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const getQuestionBankInterviewTemplate = /* GraphQL */ `
  query GetQuestionBankInterviewTemplate($id: ID!) {
    getQuestionBankInterviewTemplate(id: $id) {
      id
      group
      name
      description
      jobLevel
      interviewerRoleID
      interviewerRole {
        id
        group
        name
        description
        teamID
        createdAt
        updatedAt
        team {
          id
          name
          group
          createdAt
          updatedAt
        }
      }
      jobRoleID
      jobRole {
        id
        group
        name
        description
        teamID
        createdAt
        updatedAt
        team {
          id
          name
          group
          createdAt
          updatedAt
        }
      }
      teamID
      team {
        id
        name
        group
        createdAt
        updatedAt
      }
      questions {
        items {
          group
          interviewTemplateID
          questionID
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export const getQuestionBankQuestion = /* GraphQL */ `
  query GetQuestionBankQuestion($id: ID!) {
    getQuestionBankQuestion(id: $id) {
      id
      group
      status
      name
      description
      createdAt
      updatedAt
      categoryID
      shareability
      category {
        id
        group
        name
        description
        createdAt
        updatedAt
        teamID
        team {
          id
          name
          group
          createdAt
          updatedAt
        }
      }
      teamID
      team {
        id
        name
        group
        createdAt
        updatedAt
      }
    }
  }
`;

export const listQuestionBankQuestionsByShareability = /* GraphQL */ `
  query ListQuestionBankQuestionsByShareability($shareability: QuestionShareability!, $nextToken: String) {
    listQuestionBankQuestionsByShareability(shareability: $shareability, nextToken: $nextToken) {
      nextToken
      items {
        id
        category {
          id
          group
          name
          description
          teamID
          createdAt
          updatedAt
          team {
            id
            name
            group
            createdAt
            updatedAt
          }
        }
        categoryID
        status
        group
        name
        description
        teamID
        createdAt
        updatedAt
        shareability
        team {
          id
          name
          group
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const listQuestionBankInterviewTemplateQuestionsByQuestionID = /* GraphQL */ `
  query ListQuestionBankInterviewTemplateQuestions($questionID: ID!, $nextToken: String) {
    listInterviewTemplatesByQuestion(questionID: $questionID, nextToken: $nextToken) {
      nextToken
      items {
        group
        interviewTemplateID
        questionID
        createdAt
        updatedAt
      }
    }
  }
`;
