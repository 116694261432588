/* eslint-disable prettier/prettier */
export const loopTemplatesByOrg = /* GraphQL */ `
  query LoopEventTemplatesByOrg(
    $orgID: ID!
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    getOrganization(id: $orgID) {
      loopEvents(
        sortDirection: $sortDirection
        filter: {
          and: [
            { is_template: { eq: true } },
            { eventConfigID: { attributeExists: true } }
          ]
        }
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          orgID
          date
          id
          is_template
          template_name
          template_description
          eventleadID
          designatedRCID
          recruitingPOCID
        }
        nextToken
      }
    }
  }
`;

export const getLoopEventsByInterviewerProfile = /* GraphQL */ `
  query getLoopEventsByInterviewerProfile(
    $profileID: ID!
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    listInterviewersByProfile(
      profileID: $profileID
      sortDirection: $sortDirection
      filter: { loopEventID: { attributeExists: true } }
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        loopEvent {
          id
          maxcandidates
          taglist
          status
          restrictedLeadSignup
          restrictedShadowSignup
          location
          date
          eventstarttime
          eventendtime
          virtualevent
          createdAt
          orgID
          locked
          lockedby {
            id
            alias
            name
          }
          eventlead {
            id
            alias
            name
          }
          designatedRC {
            id
            alias
            name
          }
          recruitingPOC {
            id
            alias
            name
          }
          eventConfig {
            id
            brMode
            candidateLevels
            name
            description
            duration
            breakDuration
            maxShadowParticipants
            updatedAt
            createdAt
            isActive
            orgID
            isPushRouting
            participantConfigs {
              items {
                id
                orgID
                isActive
                name
                description
                calibrationConfigID
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          candidates {
            items {
              name
              level
              id
              hireurl
              city
            }
          }
          interviewers {
            items {
              id
              interviewerRole
              participantConfigID
              isBarRaiserInterviewer
              startDateTime
              endDateTime
              sourceOrgID
              profile {
                id
                alias
                name
                preferredTimezone
                title
              }
            }
          }
          comments(filter: { commentType: { eq: EVENT } }) {
            items {
              id
            }
          }
          updatedAt
        }
      }
      nextToken
    }
  }
`;
 
export const getLoopEventsbyOrg = /* GraphQL */ `
  query GetOrgLoopEvents(
    $orgID: ID!
    $statusDate: ModelLoopEventByOrgStatusDateCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $loopsFilter: ModelLoopEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrganization(id: $orgID) {
      id
      name
      loopEvents(
        statusDate: $statusDate,
        filter: $loopsFilter,
        limit: $limit,
        nextToken: $nextToken,
        sortDirection: $sortDirection
      ) {
        items {
          id
          maxcandidates
          taglist
          status
          restrictedLeadSignup
          restrictedShadowSignup
          location
          date
          eventstarttime
          eventendtime
          virtualevent
          createdAt
          orgID
          locked
          lockedby {
            id
            alias
            name
          }
          eventlead {
            id
            alias
            name
          }
          designatedRC {
            id
            alias
            name
          }
          recruitingPOC {
            id
            alias
            name
          }
          eventConfig {
            id
            orgID
            createdAt
            updatedAt
            brMode
            candidateLevels
            name
            description
            duration
            breakDuration
            maxShadowParticipants
            isActive
            isPushRouting
            participantConfigs {
              items {
                id
                orgID
                isActive
                name
                description
                calibrationConfigID
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          candidates {
            items {
              name
              level
              id
              hireurl
              city
            }
          }
          interviewers {
            items {
              id
              interviewerRole
              participantConfigID
              isBarRaiserInterviewer
              startDateTime
              endDateTime
              sourceOrgID
              profile {
                id
                alias
                name
                preferredTimezone
                title
              }
            }
          }
          routingHistory {
            items {
              id
              endDateTime
              role
              participantConfigID
              createdAt
              profile {
                alias
                id
                createdAt
                updatedAt
              }
              scheduleTime
              startDateTime
              state
              isBarRaiser
              profileID
              updatedAt
            }
          }
          comments(filter: { commentType: { eq: EVENT } }) {
            items {
              id
            }
          }
          updatedAt
        }
        nextToken
      }
    }
  }
`;

export const getCommentsByLoopEvent = /* GraphQL */ `
  query ListCommentsByLoopEvent(
    $loopEventID: ID!
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    listCommentsByLoopEvent(
      loopEventID: $loopEventID
      commentTypeCreatedAt: { beginsWith: { commentType: EVENT createdAt: "" } }
      sortDirection: $sortDirection
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken 
      items {
        id
        content
        profile {
          id
          alias
          email
          name
          title
        }
        restricted
        loopEventID
        createdAt
        updatedAt
      }
    }
  }
`;

export const getLoopConfigsByOrg = /* GraphQL */ `
  query GetLoopConfigsByOrg(
    $orgID: ID!
    $filter: ModelOrgLoopPSConfigurationsFilterInput
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    listConfigsByOrgType(
      orgID: $orgID
      configType: { eq: "LOOP_CONFIG" }
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgID
        configType
        group
        isActive
        brMode
        candidateLevels
        name
        description
        duration
        breakDuration
        maxShadowParticipants
        participantConfigs {
          items {
            id
            orgID
            configType
            group
            isActive
            name
            description
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getLoopConfigDefinition = /* GraphQL */ `
  query GetLoopEventConfigDefinition($id: ID!) {
    getOrgLoopPSConfigurations(id: $id) {
      id
      orgID
      group
      isActive
      isPushRouting
      brMode
      candidateLevels
      name
      description
      duration
      breakDuration
      maxShadowParticipants
      participantConfigIDs
      participantConfigs {
        items {
          id
          orgID
          configType
          group
          isActive
          name
          description
          calibrationConfigID
          calibrationConfig {
            id
            orgID
            configType
            group
            isActive
            name
            description
            maxShadowPoolSize
            minShadows
            minIndependent
            minReverseShadows
            minShepherdReviews
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const getLoopEvent = /* GraphQL */ `
  query GetLoopEvent($id: ID!) {
    getLoopEvent(id: $id) {
      id
      date
      eventstarttime
      eventendtime
      eventlead {
        id
        alias
        name
        email
        br
      }
      status
      locked
      lockedby {
        id
        alias
        name
        email
        br
      }
      prebriefbooked
      prebriefNote
      debriefbooked
      debriefNote
      calendarplaceholdersent
      virtualevent
      recruitingPOC {
        id
        alias
        name
        email
        br
      }
      designatedRC {
        id
        alias
        name
        email
        br
      }
      restrictedLeadSignup
      restrictedShadowSignup
      is_template
      template_name
      template_description
      template_creator_alias
      source_template_id
      taglist
      maxcandidates
      orgID
      comments(filter: { commentType: { eq: EVENT } }) {
        items {
          id
          createdAt
          content
          restricted
          profile {
            id
            alias
            name
            email
          }
          updatedAt
        }
        nextToken
      }
      candidates {
        items {
          id
          name
          city
          hireurl
          decision
          country
          level
          createdAt
          updatedAt
        }
        nextToken
      }
      interviewers {
        items {
          id
          profile {
            id
            alias
            firstName
            lastName
            name
            email
            level
            br
            status
            preferredTimezone
            title
            createdAt
            updatedAt
          }
          honeycode_uid
          interviewerRole
          isBarRaiserInterviewer
          sendCalendarInvite
          participantConfigID
          startDateTime
          endDateTime
          createdAt
          updatedAt
          sourceOrgID
        }
        nextToken
      }
      eventConfig {
        id
        orgID
        configType
        group
        isActive
        isPushRouting
        brMode
        candidateLevels
        name
        description
        duration
        breakDuration
        maxShadowParticipants
        participantConfigIDs
        participantConfigs {
          items {
            id
            orgID
            configType
            group
            isActive
            name
            description
            calibrationConfigID
            calibrationConfig {
              id
              orgID
              configType
              group
              isActive
              candidateLevels
              name
              description
              maxShadowPoolSize
              minShadows
              minIndependent
              minReverseShadows
              minShepherdReviews
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      location
      createdAt
      updatedAt
    }
  }
`;

export const getLoopEventInterviewers = /* GraphQL */ `
  query GetInterviewersByLoopEvent(
    $eventID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInterviewerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getLoopEvent(id: $eventID) {
      interviewers(
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          id
          profileID
          participantConfigID
          interviewerRole
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const listRoutingHistoryByLoopEvent = /* GraphQL */ `
  query listRoutingHistoryByLoopEvent($loopEventID: ID!, $limit: Int) {
    listRoutingHistoryByLoopEvent(loopEventID: $loopEventID, limit: $limit) {
      items {
        state
        role
        startDateTime
        endDateTime
        scheduleTime
        profile {
          alias
          id
          name
          email
          title
          createdAt
          updatedAt
        }
        isBarRaiser
        participantConfigID
        createdAt
        id
        updatedAt
      }
    }
}
`;

export const listLanguagesByEvent = /* GraphQL */ `
  query ListLanguagesByEventID($eventId: ID!) {
    listLanguagesByEventId(eventId: $eventId) {
      items {
        id
        eventId
        eventType
        isoCode
        proficiencyReading
        proficiencySpeaking
        proficiencyWriting
      }
    }
  }
`;
