/* eslint-disable prettier/prettier */
export const listOrganizations = /* GraphQL */`
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $collaborationsFilter: ModelOrgCollaborationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        createdAt
        updatedAt
        orgCollaborationsOutbound(filter: $collaborationsFilter, limit: $limit) {
          items {
            id
          }
        }
        orgCollaborationsInbound(filter: $collaborationsFilter, limit: $limit) {
          items {
            id
          }
        }
        status
      }
      nextToken
    }
  }
`;

export const listOrganizationsSimple = /* GraphQL */`
  query ListOrganizationsSimple(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        createdAt
        updatedAt
        status
      }
      nextToken
    }
  }
`;

export const getOrganizationDetail = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      availabilityMinDaysOut
      shadowMinDaysOut
      advancedSettings {
        enableLoopQuipExport
        enablePhoneScreenQuipExport
        useExperimentalCalendaring
        enablePromoHub
      }
      loopQuipID
      loopsExportedAt
      phoneScreenQuipID
      phoneScreensExportedAt
    }
  }
`;

export const getAllEventParticipantConfigs = /* GraphQL */ `
  query GetAllOrgEventParticipantConfigs(
    $id: ID!
    $limit: Int
    $nextToken: String
  ) {
    getOrganization(id: $id) {
      id
      loopPSConfigs(
        filter: {
          or: [
            { configType: { eq: LOOP_PARTICIPANT } },
            { configType: { eq: PHONESCREEN_PARTICIPANT } }
          ]
        },
        limit: $limit,
        nextToken: $nextToken
      ) {
        items {
          id
          orgID
          configType
          group
          isActive
          name
          description
          calibrationConfigID
          calibrationConfig {
            id
            configType
            group
            isActive
            name
            description
            maxShadowPoolSize
            minShadows
            minIndependent
            minReverseShadows
            minShepherdReviews
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;

export const getEventParticipantsByOrg = /* GraphQL */ `
  query GetOrgEventParticipantConfigsByType(
    $orgID: ID!
    $configType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrgLoopPSConfigurationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConfigsByOrgType(
      orgID: $orgID
      configType: $configType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgID
        configType
        group
        isActive
        name
        description
        calibrationConfig {
          id
          orgID
          configType
          group
          isActive
          name
          description
          maxShadowPoolSize
          minShadows
          minIndependent
          minReverseShadows
          minShepherdReviews
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getEventCalibrationsByOrg = /* GraphQL */ `
  query GetOrgEventCalibrationConfigsByType(
    $orgID: ID!
    $configType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrgLoopPSConfigurationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConfigsByOrgType(
      orgID: $orgID
      configType: $configType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgID
        configType
        group
        isActive
        name
        description
        maxShadowPoolSize
        minShadows
        minReverseShadows
        minShepherdReviews
        minIndependent
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getCalibrationDefinition = /* GraphQL */ `
  query GetCalibrationDefinition($id: ID!) {
    getOrgLoopPSConfigurations(id: $id) {
      id
      orgID
      configType
      group
      isActive
      name
      description
      maxShadowPoolSize
      minShadows
      minReverseShadows
      minShepherdReviews
      minIndependent
      createdAt
      updatedAt
    }
  }
`;

export const getAllActiveOrgEventCalibrationConfigs = /* GraphQL */ `
  query GetActiveOrgEventCalibrationConfigs(
    $orgID: ID!
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    getOrganization(id: $orgID) {
      loopPSConfigs(
        sortDirection: $sortDirection
        limit: $limit
        nextToken: $nextToken
        filter: {
          and: [
            { isActive: { eq: true } },
            {
              or: [
                { configType: { eq: LOOP_CALIBRATION } },
                { configType: { eq: PHONESCREEN_CALIBRATION } }
              ]
            }
          ]
        }
      ) {
        items {
          id
          orgID
          configType
          group
          isActive
          name
          description
          maxShadowPoolSize
          minShadows
          minReverseShadows
          minShepherdReviews
          minIndependent
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;

export const getAllOrgCollaborations = /* GraphQL */ `
  query GetAllOrgCollaborations($id: ID!) {
    getOrganization(id: $id) {
      id
      orgCollaborationsOutbound(filter: { isActive: { eq: true } }) {
        items {
          id
          isActive
          isPendingAuthorization
          targetOrgID
          barRaisers
          sharedLoopParticipants {
            originParticipantId
            targetParticipantId
            mode
          }
          sharedPSParticipants {
            originParticipantId
            targetParticipantId
            mode
          }
          originParticipants {
            items {
              id
              calibrationConfigID
            }
          }
          targetParticipants {
            items {
              id
              calibrationConfigID
            }
          }
          pendingChangeSpec
          createdAt
          updatedAt
        }
        nextToken
      }
      orgCollaborationsInbound(filter: { isActive: { eq: true } }) {
        items {
          id
          isActive
          isPendingAuthorization
          originatingOrgID
          barRaisers
          sharedLoopParticipants {
            originParticipantId
            targetParticipantId
            mode
          }
          sharedPSParticipants {
            originParticipantId
            targetParticipantId
            mode
          }
          originParticipants {
            items {
              id
              calibrationConfigID
            }
          }
          targetParticipants {
            items {
              id
              calibrationConfigID
            }
          }
          pendingChangeSpec
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;

export const getOrgOutboundCollaborations = /* GraphQL */ `
  query GetOrgOutboundCollaborations(
    $id: ID!
    $loopPSConfigsLimit: Int
  ) {
    getOrganization(id: $id) {
      id
      name
      description
      loopPSConfigs(
        filter: {
          or: [
            { configType: { eq: LOOP_PARTICIPANT } },
            { configType: { eq: PHONESCREEN_PARTICIPANT } }
          ]
        },
        limit: $loopPSConfigsLimit
      ) {
        items {
          id
          configType
          isActive
          name
          description
        }
        nextToken
      }
      orgCollaborationsOutbound {
        items {
          id
          isActive
          isPendingAuthorization
          targetOrg {
            id
            name
            description
            loopPSConfigs(
              filter: {
                or: [
                  { configType: { eq: LOOP_PARTICIPANT } },
                  { configType: { eq: PHONESCREEN_PARTICIPANT } }
                ]
              },
              limit: $loopPSConfigsLimit
            ) {
              items {
                id
                configType
                isActive
                name
                description
              }
              nextToken
            }
            georegion
            geoarea
            createdAt
            updatedAt
          }
          barRaisers
          sharedLoopParticipants {
            originParticipantId
            targetParticipantId
            mode
          }
          sharedPSParticipants {
            originParticipantId
            targetParticipantId
            mode
          }
          pendingChangeSpec
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;

export const getOrgInboundCollaborations = /* GraphQL */ `
  query GetOrgInboundCollaborations(
    $id: ID!
    $loopPSConfigsLimit: Int
  ) {
    getOrganization(id: $id) {
      id
      name
      description
      loopPSConfigs(
        filter: {
          or: [
            { configType: { eq: LOOP_PARTICIPANT } },
            { configType: { eq: PHONESCREEN_PARTICIPANT } }
          ]
        },
        limit: $loopPSConfigsLimit
      ) {
        items {
          id
          configType
          isActive
          name
          description
        }
        nextToken
      }
      orgCollaborationsInbound {
        items {
          id
          isActive
          isPendingAuthorization
          originatingOrg {
            id
            name
            description
            loopPSConfigs(
              filter: {
                or: [
                  { configType: { eq: LOOP_PARTICIPANT } },
                  { configType: { eq: PHONESCREEN_PARTICIPANT } }
                ]
              },
              limit: $loopPSConfigsLimit
            ) {
              items {
                id
                configType
                isActive
                name
                description
              }
              nextToken
            }
            georegion
            geoarea
            createdAt
            updatedAt
          }
          barRaisers
          sharedLoopParticipants {
            originParticipantId
            targetParticipantId
            mode
          }
          sharedPSParticipants {
            originParticipantId
            targetParticipantId
            mode
          }
          pendingChangeSpec
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;

export const getOrgRoleLDAPGroups = /* GraphQL */ `
  query GetOrgRoleLDAPGroups(
    $id: ID!
    $roleLDAPGroupsFilter: ModelOrgRoleLDAPGroupsFilterInput
  ) {
    getOrganization(id: $id) {
      id
      roleLDAPGroups(filter: $roleLDAPGroupsFilter) {
        items {
          id
          role
          group
        }
        nextToken
      }
    }
  }
`;

export const getOrgRoleGroups = /* GraphQL */ `
  query GetOrgRoleGroups(
    $id: ID!
    $roleGroupsFilter: ModelRolePermissionGroupFilterInput
  ) {
    getOrganization(id: $id) {
      id
      roleGroups(filter: $roleGroupsFilter) {
        items {
          app
          id
          role
          group
        }
        nextToken
      }
    }
  }
`;

export const getOrgBarRaisers = /* GraphQL */ `
  query GetOrgBarRaisers(
    $id: ID!
    $barRaisersFilter: ModelBarRaiserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrganization(id: $id) {
      id
      name
      barRaisers(filter: $barRaisersFilter, limit: $limit, nextToken: $nextToken) {
        items {
          id
          profile {
            id
            alias
            name
            email
            level
            brit
            manager
            l2manager
            status
            title
            activeOrg
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;

export const listOrgTrainings = /* GraphQL */ `
  query ListOrgTrainings(
    $id: ID!
    $limit: Int
    $nextToken: String
    $trainingFilter: ModelOrgTrainingFilterInput
  ) {
    getOrganization(id: $id) {
      id
      name
      orgtrainings(limit: $limit, nextToken: $nextToken, filter: $trainingFilter) {
        items {
          id
          training
          trainingURL
          training_id
          active
          is_version_strict
          minimum_acceptable_version
          requiredTraining
          requiredTrainingForPS
          requiredTrainingForLoop
          orgID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;

export const getOrgDefaultCalibrationShepherdDetails = /* GraphQL */ `
  query GetOrgDefaultCalibrationShepherdDetails($id: ID!) {
    getOrganization(id: $id) {
      calibrationShepherdMode
      defaultCalibrationShepherd
    }
  }
`;

export const getOrgEventTags = /* GraphQL */ `
  query getOrgEventTags($id: ID!) {
    getOrganization(id: $id) {
      eventTags {
        group
        value
      }
    }
  }
`;
