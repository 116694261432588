/* eslint-disable prettier/prettier */

export const getKnetUserTranscriptAllVersionsByTrainingID = /* GraphQL */ `
  query GetKnetUserTranscriptAllVersionsByTrainingID(
    $amzn_empl_id: String!
    $training_id: String!
  ) {
    getKnetUserTranscriptAllVersionsByTrainingID(
      amzn_empl_id: $amzn_empl_id
      training_id: $training_id
    ) {
      al_lms
      csod_user_id
      amzn_empl_id
      amzn_alias
      is_latest_training_version
      training_version
      training_id
      transcripts {
        training_id
        training_reg_number
        is_greatest_reg_num
        transcript_status_id
        is_success
        transcript_reg_date
        transcript_completion_date
        __typename
      }
      __typename
    }
  }
`;

export const getKnetTrainingById = /* GraphQL */ `
  query GetKnetTrainingById($id: String!) {
    getKnetTrainingById(training_id: $id) {
      all_training_versions {
        is_latest_training_version
        localization {
          training_title
        }
        training_created_date
        training_version
      }
      is_latest_training_version
      localization {
        training_title
      }
      training_created_date
      training_version
    }
  }
`;
