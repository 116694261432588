/* eslint-disable prettier/prettier */

export const getFeedbackByEventAndAuthor = /* GraphQL */ `
  query FeedbackByLoopEventAndAuthor(
    $profileID: ID!
    $loopEventID: ID
    $phoneScreenID: ID
    $limit: Int
    $commentFilter: ModelCommentFilterInput
    $nextToken: String
  ) {
    getProfile(id: $profileID) {
      alias
      comments(
        commentTypeLoopEventIDPhoneScreenID: {
          eq: { commentType: FEEDBACK, loopEventID: $loopEventID, phoneScreenID: $phoneScreenID }
        }
        filter: $commentFilter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          id
          content
          rating
          interviewerID
          restricted
          createdAt
          updatedAt
        }
        nextToken
      }
      email
      id
      name
      title
    }
  }
`;

export const listFeedbackForProfile = /* GraphQL */ `
  query ListFeedbackForProfile(
    $interviewerProfileID: ID!
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeedbackForProfile(
      interviewerProfileID: $interviewerProfileID
      commentType: { between: ["FEEDBACK", "PROFILE"] }
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        commentType
        content
        createdAt
        id
        interviewer {
          id
          isBarRaiserInterviewer
          participantConfig {
            description
            id
            name
          }
          interviewerRole
        }
        loopEvent {
          date
          eventstarttime
          id
          orgID
        }
        phoneScreen {
          date
          eventstarttime
          id
          orgID
        }
        profile {
          alias
          email
          id
          name
          title
        }
        rating
        restricted
        updatedAt
      }
      nextToken
    }
  }
`;
