/* eslint-disable prettier/prettier */

export const getAuditTrailByProfile = /* GraphQL */ `
  query GetAuditTrailsByProfile(
    $profileID: ID!,
    $filter: ModelAuditTrailsFilterInput
    $limit: Int,
    $nextToken: String
  ) {
    getAuditTrailByProfile(
      profileID: $profileID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        changeJustification
        createdAt
        humanReadableLog
        id
        modifiedBy
        recordType
      }
      nextToken
    }
  }
`;

export const listAuditTrailsByEventId = /* GraphQL */ `
  query ListAuditTrailsByEventId(
    $eventId: ID!,
    $filter: ModelAuditTrailsFilterInput
    $limit: Int,
    $nextToken: String
  ) {
    listAuditTrailsByEventId(
      eventId: $eventId
      createdAt: { ge: "2024-05-13T00:00:00.000Z" }
      sortDirection: DESC
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        changeJustification
        createdAt
        humanReadableLog
        id
        modifiedBy
        recordType
      }
      nextToken
    }
  }
`;
